<template>
  <div class="scientificResearch">
    <img
      src="../../assets/image/keyan/bg.png"
      style="height: 440px; width: 100vw"
      alt=""
    />
    <div class="content2">
      <div class="gaishu">
        <div class="title">
          <div class="english-yellow">{{$t('scientificResearch.Projectoverview')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('scientificResearch.项目概述')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="img">
          <img src="../../assets/image/keyan/gaishu.png" />
        </div>
      </div>
    </div>
    <div class="bgc f7">
      <div class="content3">
        <div class="jianjie">
          <div class="title">
            <div class="english-yellow">{{$t('scientificResearch.Productintroduction')}}</div>
            <div class="service-con">
              <div class="servicetop">{{$t('scientificResearch.产品简介')}}</div>
              <div class="line2"></div>
            </div>
          </div>
          <div class="img" >
            <img src="../../assets/image/keyan/jianjie.png"  />
          <img style="height:400px; margin-top:30px" src="../../assets/image/keyan/jiangxiang.png" />
          </div>
        </div>
      </div>
    </div>
<!--    <img src="@/assets/image/keyan/zsxlwfd.png" style="height: 968px;width:1920px;">-->
    <div class="content2">
      <img src="@/assets/image/keyan/zsxlwfd.png" style="height: 968px;width:1920px;position: relative;left:-500px">
    </div>
    <div class="bgc af6">
      <div class="content3">
        <div class="fudao">
          <div class="text">{{$t('scientificResearch.综述型论文辅导')}}</div>
          <div class="text2">{{$t('scientificResearch.Literature')}}</div>
          <div class="div">
            <div>
              <div class="text3">
                <div class="yuan"></div>
                <div>{{$t('scientificResearch.项目周期')}}</div>
              </div>
              <div class="text4">
               {{$t('scientificResearch.包含12课时授课')}} 
              </div>
              <div class="text3">
                <div class="yuan"></div>
                <div>{{$t('scientificResearch.适合学生')}}</div>
              </div>
              <div class="text4">{{$t('scientificResearch.适合全部学科')}}</div>
              <div class="text3">
                <div class="yuan"></div>
                <div>{{$t('scientificResearch.项目导师')}}</div>
              </div>
              <div class="text4">{{$t('scientificResearch.双一流名校博士')}}</div>
            </div>
            <div>
              <div class="text3">
                <div class="yuan"></div>
                <div>{{$t('scientificResearch.学术成果')}}</div>
              </div>
              <div class="text4">
               {{$t('scientificResearch.理工')}} 
              </div>
              <div class="text3">
                <div class="yuan"></div>
                <div>{{$t('scientificResearch.发表级别')}}</div>
              </div>
              <div class="text4">{{$t('scientificResearch.国际会议录用')}}</div>
              <div class="text3">
                <div class="yuan"></div>
                <div>{{$t('scientificResearch.项目收获')}}</div>
              </div>
              <div class="text4">
               {{$t('scientificResearch.篇独作的论文')}}
                <br />
                {{$t('scientificResearch.录用通知')}}
              </div>
            </div>
          </div>

         
        </div>
      </div>
    </div>
     <div class="new img" >
         <img style="height:500px; margin-top:30px" src="../../assets/image/keyan/jiangxiang2.png" />
      </div>
    <div class="content2">
      <img src="@/assets/image/keyan/hxxlwfd.png" style="height: 968px;width:1920px;position: relative;left:-500px">
      <div class="title">
        <div class="english-yellow">{{$t('scientificResearch.Studentstory')}}</div>
        <div class="service-con">
          <div class="servicetop">{{$t('scientificResearch.代表性案例')}}</div>
          <div class="line2"></div>
        </div>
      </div>
      <div class="anli">
        <!-- <div class="touxiang">
          <div @click="storyClick(1)" >
            <img src="../../assets/image/oneimg.png" />
          </div>
          <div @click="storyClick(2)">
            <img src="../../assets/image/twoimg.png" />
          </div>
          <div @click="storyClick(3)">
            <img src="../../assets/image/threeimg.png" />
          </div>
        </div> -->
        <div class="details" v-if="storyindex==1">
          <div class="f1">
            <div>
              <div class="text">
                <div class="yuan"></div>
                <div>Matt Wan</div>
              </div>
              <div class="text2">{{$t('scientificResearch.学习方向')}}</div>
              <div class="text2">{{$t('scientificResearch.高中学校')}}</div>
              <div class="text2">{{$t('scientificResearch.学生成绩')}}</div>
              <div class="text2">
                {{$t('scientificResearch.课题名称')}}
              </div>
            </div>
            <div class="img">
              <img src="../../assets/image/keyan/jiangxiang3.png" />
            </div>
          </div>
          <div class="f2">
           {{$t('scientificResearch.Matt钟情于心理学专业')}} 
            <br />
            <br />
            {{$t('scientificResearch.在确认了心理学的大方向之后')}}
            <br />
            {{$t('scientificResearch.位于京都的会议')}}<br />
            {{$t('scientificResearch.College')}}<br />
          </div>
        </div>
        <div class="details" v-if="storyindex==2">
          <div class="f1">
            <div>
              <div class="text">
                <div class="yuan"></div>
                <div>Matt Wan</div>
              </div>
              <div class="text2">{{$t('scientificResearch.学习方向')}}</div>
              <div class="text2">{{$t('scientificResearch.高中学校')}}</div>
              <div class="text2">{{$t('scientificResearch.学生成绩')}}</div>
              <div class="text2">
                {{$t('scientificResearch.课题名称')}}
              </div>
            </div>
            <div class="img">
              <img src="../../assets/image/noimg.png" />
            </div>
          </div>
          <div class="f2">
            {{$t('scientificResearch.Matt钟情于心理学专业')}}
            <br />
            <br />
            {{$t('scientificResearch.在确认了心理学的大方向之后')}}
            <br />
            {{$t('scientificResearch.位于京都的会议')}}<br />
            {{$t('scientificResearch.College')}}<br />
          </div>
        </div>
        <div class="details" v-if="storyindex==3">
          <div class="f1">
            <div>
              <div class="text">
                <div class="yuan"></div>
                <div>Matt Wan</div>
              </div>
              <div class="text2">{{$t('scientificResearch.学习方向')}}</div>
              <div class="text2">{{$t('scientificResearch.高中学校')}}</div>
              <div class="text2">{{$t('scientificResearch.学生成绩')}}</div>
              <div class="text2">
                {{$t('scientificResearch.课题名称')}}
              </div>
            </div>
            <div class="img">
              <img src="../../assets/image/noimg.png" />
            </div>
          </div>
          <div class="f2">
            {{$t('scientificResearch.Matt钟情于心理学专业')}}
            <br />
            <br />
            {{$t('scientificResearch.在确认了心理学的大方向之后')}}
            <br />
            {{$t('scientificResearch.位于京都的会议')}}<br />
            {{$t('scientificResearch.College')}}<br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        storyindex:1
      };
    },
    methods:{
      storyClick(e){
        this.storyindex=e
      }
    }
  };
</script>
<style lang="scss">
  .new  {
   
     padding-bottom: 70px;
     > img {
    width: 1176px;
    height: 258px;
     }
          
}
  .scientificResearch {
    padding-bottom: 100px;
    .f7 {
      background-color: #f7f7f7;
    }
    .af6 {
      background-color: #fefaf6;
      padding-bottom: 50px;
    }
    .bgc {
      width: 100%;
      padding-top: 1px;

      .content3 {
        width: 1176px;
        margin: 0 auto;
        .fudao {
          text-align: left;
          .div {
            display: flex;
            justify-content: space-between;
            > div:nth-child(1) {
              width: 394px;
            }
            > div {
              width: 424px;
              .text4 {
                margin-top: 16px;
                font-size: 18px;
                font-weight: 400;
                color: #333333;
                line-height: 32px;
              }
              .text3 {
                display: flex;
                align-items: center;
                font-size: 24px;
                font-weight: 600;
                margin-top: 40px;
                .yuan {
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                  background-color: #fe992b;
                  margin-right: 10px;
                }
              }
            }
          }
          .text {
            margin-top: 70px;
            font-size: 32px;
            font-weight: 600;
            color: #333333;
          }
          .text2 {
            margin-top: 5px;
            font-size: 24px;
            font-weight: 400;
            color: #999999;
          }
        }
        .jianjie {
          .img {
            padding-bottom: 70px;
            > img {
              width: 1176px;
              height: 258px;
            }
          }
        }
      }
    }
    .content2 {
      width: 1176px;
      margin: 0 auto;
      .anli {
        display: flex;
        text-align: left;
        .details {
          margin-left: 40px;
          .f2 {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
          }
          .f1 {
            display: flex;
            margin-bottom: 40px;
            .img {
              margin-left: 40px;
              > img {
                width: 597px;
                height: 242px;
              }
            }
            .text2 {
              width: 379px;
              margin-bottom: 10px;
              font-size: 18px;
              font-weight: 400;
              color: #333333;
            }
            .text {
              display: flex;
              align-items: center;
              font-size: 24px;
              font-weight: 600;
              margin-bottom: 16px;
              .yuan {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: #fe992b;
                margin-right: 10px;
              }
            }
          }
        }
        .touxiang {
          width: 120px;
          > div {
            margin-bottom: 20px;
            > img {
              width: 120px;
              height: 120px;
              border-radius: 50%;
            }
          }
        }
      }
      .lunwen {
        text-align: left;
        padding-bottom: 70px;
        .div {
          display: flex;
          > div:nth-child(2) {
            padding-top: 40px;
            .img {
              margin-bottom: 16px;
              > img {
                width: 450px;
                height: 220px;
              }
            }
          }
          > div:nth-child(1) {
            margin-right: 183px;
            width: 543px;
            .text4 {
              margin-top: 16px;
              font-size: 18px;
              font-weight: 400;
              color: #333333;
              line-height: 32px;
            }
            .text3 {
              display: flex;
              align-items: center;
              font-size: 24px;
              font-weight: 600;
              margin-top: 40px;
              .yuan {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: #fe992b;
                margin-right: 10px;
              }
            }
          }
        }
        .text {
          margin-top: 70px;
          font-size: 32px;
          font-weight: 600;
          color: #333333;
        }
        .text2 {
          margin-top: 5px;
          font-size: 24px;
          font-weight: 400;
          color: #999999;
        }
      }
      .gaishu {
        .img {
          padding-bottom: 70px;
          > img {
            width: 1176px;
            height: 450px;
          }
        }
      }
    }
    .title {
      position: relative;
      height: 150px;
      margin-top: 50px;

      .english-yellow {
        color: #fe992b;
        font-size: 44px;
        opacity: 0.3;
      }

      .service-con {
        width: 100%;
        position: absolute;
        top: 17px;
      }

      .line2 {
        width: 124px;
        height: 6px;
        border-radius: 3px;
        background-color: #fe992b;
        margin: 0 auto;
      }

      .servicetop {
        // width: 160px;
        font-size: 32px;
        color: #333;
        font-weight: bold;
        padding: 10px 0;
        // border-bottom: 6px solid #fe992b;
        border-radius: 3px;
        margin: 0 auto;
      }
    }
  }
</style>
